import { FaBell } from "react-icons/fa";
import { useState, useEffect, useCallback } from "react";

import socket from "src/socket";
import { Toast, LoadingSpinner } from "src/components";
import { useCreateOneCall, useGetAllCall } from "src/services";

export default function Header() {
  const [reservations, setReservations] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isAudioAllowed, setIsAudioAllowed] = useState(false);

  const { data, refetch, isLoading, isFetching } = useGetAllCall("reservations/awaiting");
  const isAwaitingLoading = isLoading || isFetching;

  const playNotificationSound = useCallback(() => {
    if (isAudioAllowed) {
      const audio = new Audio("/notification.wav");
      audio.play().catch((error) => console.error("Audio playback failed:", error));
    }
  }, [isAudioAllowed]);

  useEffect(() => {
    if (data?.data.success) setReservations(data.data.data);
  }, [data]);

  useEffect(() => {
    const handleNewReservation = (data) => {
      playNotificationSound();
      refetch();
    };
    socket.on("new_reservation", handleNewReservation);

    return () => socket.off("new_reservation", handleNewReservation);
  }, [playNotificationSound]);

  const handleMutation = useCallback(
    (action, reservationId) => {
      action.mutate(
        { reservationId },
        {
          onSuccess: (data) => {
            if (data?.data?.success) {
              refetch();
              Toast(data?.data?.message, "success");
            }
          },
          onError: () => Toast("Échec du traitement de la demande", "error"),
        }
      );
    },
    [refetch]
  );

  const confirmMutation = useCreateOneCall("reservations/confirm");
  const rejectMutation = useCreateOneCall("reservations/reject");
  const isWindowLoading = confirmMutation.status === "pending" || rejectMutation.status === "pending";

  const handleUserInteraction = useCallback(() => {
    setIsAudioAllowed(true);
    window.removeEventListener("click", handleUserInteraction);
  }, []);

  useEffect(() => {
    window.addEventListener("click", handleUserInteraction);
    return () => window.removeEventListener("click", handleUserInteraction);
  }, [handleUserInteraction]);

  useEffect(() => {
    const event = new MouseEvent("click", { view: window, bubbles: true, cancelable: true });
    window.dispatchEvent(event);
  }, []);

  return (
    <div className="flex top-2.5 right-2.5 justify-between items-center pt-[12px] pr-[12px] w-full">
      <div className="flex-grow"></div>
      <div className="relative bg-primary p-2 rounded-full">
        <FaBell className="text-2xl cursor-pointer" onClick={() => setIsDropdownOpen((prev) => !prev)} />
        {reservations?.length > 0 && <div className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full h-5 w-5 flex items-center justify-center text-xs">{reservations?.length}</div>}
        {isDropdownOpen && (
          <div className="absolute right-0 mt-2 w-72 bg-white border border-gray-300 rounded-lg shadow-lg max-h-64 overflow-y-auto">
            {isWindowLoading || isAwaitingLoading ? (
              <LoadingSpinner />
            ) : reservations?.length === 0 ? (
              <p className="p-2 text-center text-gray-500">Aucune réservation en attente</p>
            ) : (
              <ul className="divide-y divide-gray-200">
                {reservations.map((reservation, index) => (
                  <li key={index} className={`p-2 flex flex-col ${index % 2 === 0 ? "bg-gray-500" : "bg-white"}`}>
                    <p className="text-lg font-semibold bg-green-500 p-2 text-center">🙍‍♂️ {reservation.name}</p>
                    <div className="text-md flex items-center justify-around font-semibold bg-red-500">
                      <div>{reservation.nbOfPeople} 👨‍👨‍👦</div>
                      <div>à {reservation.date.split("T")[1].split(":00.")[0]} 🕔</div>
                    </div>
                    <div className="text-md flex items-center justify-around font-semibold bg-green-500">
                      <div>{reservation.eventName}</div>
                      <div>{reservation.date.split("T")[0]} 📅</div>
                    </div>

                    <div className="flex justify-between mt-2">
                      <button className="bg-green-500 text-white px-2 py-1 rounded" onClick={() => handleMutation(confirmMutation, reservation._id)}>
                        Accepter
                      </button>

                      <button className="bg-red-500 text-white px-2 py-1 rounded" onClick={() => handleMutation(rejectMutation, reservation._id)}>
                        Rejeter
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
