import Cookies from "js-cookie";
import { BiLogOut } from "react-icons/bi";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { userContext } from "src/context";
import { capitalizeFirstLetter } from "src/utils";
import LogoImage from "src/assets/triple-8.svg";
import { control } from "src/assets/sidebar-icons";

function Sidebar({ tabs, isSidebarOpen, setIsSidebarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  const { user, setUser } = useContext(userContext);
  const [activeMenuItem, setActiveMenuItem] = useState("");
  const locationTitle = location.pathname.split("dashboard/")[1];

  const handleClick = (title) => {
    navigate(title);
    setActiveMenuItem(title);
  };

  const handleLogoutClick = () => {
    Cookies.remove("x-triple-8");
    setUser(null);
    navigate("/auth/login");
  };

  useEffect(() => setActiveMenuItem(locationTitle), [locationTitle]);

  const arrowClass = `absolute cursor-pointer rounded-full -right-5 top-4 border-2 border-secondary z-110 ${!isSidebarOpen ? "rotate-180" : ""}`;
  const sidebarClass = `duration-500 z-100 h-screen bg-primary fixed ${isSidebarOpen ? "w-[12rem] px-3" : "w-[3rem] md:w-[3.7rem] p-1 md:p-2"}`;

  const iconClass = (menu) => `${activeMenuItem === menu.title ? "text-primary" : "text-secondary"}`;
  const menuItemTitleClass = (menu) => `${!isSidebarOpen ? "hidden" : ""} text-[18px] ${activeMenuItem === menu.title ? "text-primary" : "text-secondary"}`;
  const getMenuItemClass = (menu) => `flex rounded-lg p-2 cursor-pointer text-primary font-bold text-md gap-x-4 ${menu.gap ? "mt-8" : "mt-2"} ${activeMenuItem === menu.title ? "bg-secondary" : "hover:bg-tertiary"}`;

  return (
    <div className={sidebarClass}>
      <img src={control} alt="Arrow" className={arrowClass} onClick={() => setIsSidebarOpen(!isSidebarOpen)} />
      <div className="overflow-y-auto h-full hide-scrollbar">
        <div className="flex flex-col items-center duration-1000">
          {user && <h1 className={`text-secondary font-bold mx-auto text-3xl transition-delayed mt-3 ${!isSidebarOpen ? "hidden" : ""}`}>{capitalizeFirstLetter(user.username)}</h1>}
          <img src={LogoImage} alt="Triple 8 Logo" className={!isSidebarOpen ? "scale-0 hidden" : ""} />
        </div>

        <ul className={`rounded-lg select-none ${!isSidebarOpen ? "mt-6" : ""}`}>
          {tabs.map((menu, index) => (
            <li key={index} className={getMenuItemClass(menu)} onClick={() => handleClick(menu.title)}>
              <span className={iconClass(menu)}>{menu.icon}</span>
              <p className={menuItemTitleClass(menu)}>{capitalizeFirstLetter(menu.title)}</p>
            </li>
          ))}
          <li className="flex justify-center mt-5 mb-3 rounded-full p-2 bg-red-600 cursor-pointer" onClick={handleLogoutClick}>
            <BiLogOut size={26} style={{ color: "white" }} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
