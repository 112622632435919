import { Routes, Route } from "react-router-dom";

import LoginPage from "./LoginPage";

const AuthRouting = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
    </Routes>
  );
};
export default AuthRouting;
