import Slider from "react-slick";
import { useEffect, useState } from "react";

const ImageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3500,
  };

  const [images, setImages] = useState([]);

  useEffect(() => {
    const handleResize = async () => {
      if (window.innerWidth <= 1025) {
        // Dynamically load small images
        const smallImages = await Promise.all([import("../../assets/slider/image-small-1.jpg"), import("../../assets/slider/image-small-2.jpg"), import("../../assets/slider/image-small-3.jpg"), import("../../assets/slider/image-small-4.jpg")]);
        setImages(smallImages.map((mod) => mod.default));
      } else {
        // Dynamically load large images
        const largeImages = await Promise.all([import("../../assets/slider/image-1.jpg"), import("../../assets/slider/image-2.jpg"), import("../../assets/slider/image-3.jpg"), import("../../assets/slider/image-4.jpg")]);
        setImages(largeImages.map((mod) => mod.default));
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-screen h-screen">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index + 1}`} className="w-full h-screen object-cover" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
