import Cookies from "js-cookie";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { onChange } from "src/utils";
import { userContext } from "src/context";
import { useCreateOneCall } from "src/services";
import LogoImage from "src/assets/triple-8.svg";
import BackgroundImage from "src/assets/login-back.jpg";
import { CustomButton, CustomInput, Toast } from "src/components";

const LoginPage = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(userContext);

  let defaultValues = { username: "", password: "" };
  const [values, setValues] = useState(defaultValues);

  const loginMutation = useCreateOneCall("auth/login");
  const isLoading = loginMutation.status === "pending";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!values.username || !values.password) return Toast("Les deux champs sont obligatoires !!!!", "error");

    loginMutation.mutate(values, {
      onSuccess: (data) => {
        if (data?.data?.success) {
          Toast(data.data.message);
          setUser(data.data.data.user);
          Cookies.set("x-triple-8", data.data.data.token, { expires: 1 });
          return navigate("/dashboard/reservations");
        } else setValues(defaultValues);
      },
      onError: (error) => Toast("Échec du traitement de la demande", "error"),
    });
  };

  const formInputs = [
    { id: 1, name: "username", type: "text", placeholder: "Nom" },
    { id: 2, name: "password", type: "password", placeholder: "Mot de passe" },
  ];

  const maxWidth = "max-w-[370px]";

  return (
    <div className="h-screen flex flex-col items-center justify-center" style={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
      <img src={LogoImage} alt="Monjay Logo" className={`select-none ${maxWidth}`} />

      <div className={`mb-20 w-full ${maxWidth}`}>
        <form className="space-y-6" onSubmit={handleSubmit}>
          {formInputs.map((input) => (
            <CustomInput key={input.id} type={input.type} maxWidth="w-full" {...input} value={values[input.name]} onChange={onChange(setValues, values)} />
          ))}
          <CustomButton type="submit" isLoading={isLoading} disabled={isLoading} text={isLoading ? "Connexion..." : "Se connecter"} onClick={handleSubmit} customClass="w-full" />
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
