import { useQuery } from "@tanstack/react-query";

import { getPaginatedAPICall } from "../apiCalls";

/**
 * useGetPaginatedCall is a custom hook that uses the useQuery hook from react-query to fetch paginated data from an API.
 *
 * @param {string} context - The context of the API call, example "products"
 * @param {number} pageNo - The current page number
 * @param {number} limit - The number of items per page
 * @param {string} queryText - The queryText: string.
 * @param {string} specialQuery - specialQuery
 *
 * @returns {object} The result object from the useQuery hook. This object contains several properties that can be used to determine the state of the query and the data it returns.
 */
export function useGetPaginatedCall(context, pageNo, limit, queryText, specialQuery, enabled = true) {
  const queryKey = [context, pageNo, limit, queryText, specialQuery];

  return useQuery({
    queryKey,
    queryFn: () => getPaginatedAPICall(context, pageNo, limit, queryText, specialQuery),
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
    enabled,
  });
}
