import { IoMdClose } from "react-icons/io";

function PageWrapper({ children, withBackOption = false, title = "this is my title", onXClickFunctionality, useMarginBottomAuto = false }) {
  const margin = "m-[1.5%]";
  const padding = "xl:px-[2%] l:px-[10%] md:px-[2%] py-[2.3%] xl:py-[2%] sm:py-[1.7%]";

  const onXClick = () => {
    if (withBackOption && onXClickFunctionality) onXClickFunctionality();
    window.history.back();
  };

  return (
    <div className={`flex flex-col items-center justify-center bg-primary min-h-[92%] rounded-2xl md:rounded-3xl border-x-2 border-black shadow-lg ${margin} ${padding}`}>
      <div className="flex items-start justify-between bg-secondary mb-4 mt-1 p-2.5 sm:p-3.5 rounded-3xl w-full border-4 border-tertiary">
        <div className="cursor-pointer text-center text-secondary mr-2" onClick={onXClick}>
          {withBackOption ? <IoMdClose size={35} className="text-primary fill-current" /> : null}
        </div>
        <div className="w-0 flex-grow text-center">
          <h1 className="text-primary font-bold text-[1.2rem] sm:text-lg md:text-2xl lg:text-3xl truncate">{title.toUpperCase()}</h1>
        </div>
        <div>{withBackOption ? <IoMdClose size={35} className="text-transparent fill-current" /> : null}</div>
      </div>
      <div className={`w-full ${useMarginBottomAuto ? "mb-auto" : "m-auto"}`}>{children}</div>
    </div>
  );
}

export default PageWrapper;
