import { GrNotes } from "react-icons/gr";
import { MdPeopleAlt } from "react-icons/md";
import { LuCalendarDays } from "react-icons/lu";
import { GiSteeringWheel,GiNotebook } from "react-icons/gi";
import { RiOrganizationChart } from "react-icons/ri";
import { IoIosStats, IoMdSettings } from "react-icons/io";
import { FaEarthOceania, FaPeopleGroup } from "react-icons/fa6";
import { FaBoxOpen, FaGift, FaCarSide, FaShippingFast } from "react-icons/fa";

 const Icons = {
  FaGift,
  GrNotes,
  FaBoxOpen,
  FaCarSide,
  IoIosStats,
  GiNotebook,
  MdPeopleAlt,
  IoMdSettings,
  FaPeopleGroup,
  FaEarthOceania,
  FaShippingFast,
  LuCalendarDays,
  GiSteeringWheel,
  RiOrganizationChart,
};

export default Icons;
