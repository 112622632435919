import { useLocation, useNavigate } from "react-router-dom";

import { useGetAllCall } from "src/services";
import { CustomButton, FetchStateHandler, PageWrapper } from "src/components";

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const reservationId = queryParams.get("reservationId");

  const { data, isLoading, isFetching } = useGetAllCall(`reservations/cancelReservation?reservationId=${reservationId}`);

  const message = data?.data?.message;

  const loading = isLoading || isFetching;

  return (
    <PageWrapper title="Reservations">
      <div className="flex flex-col gap-4 items-center justify-center">
        <FetchStateHandler isLoading={loading} error={false}>
          <h1>{message}</h1>
          <CustomButton text="Accéder à la page d'accueil" onClick={() => navigate("/")} />
        </FetchStateHandler>
      </div>
    </PageWrapper>
  );
};

export default Index;
