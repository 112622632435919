import Client from "src/services/client";
import { Toast } from "src/components";

export const createOneAPICall = async (context, data) => {
  try {
    const response = await Client.post(`/${context}`, data);

    return response;
  } catch (error) {
    // "error" below is the toast type
    Toast(error.response.data.message || "Il y a une erreur sur le serveur, veuillez contacter le support", "error");
  }
};
