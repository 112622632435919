export default function Button({ text = "", onClick, disabled = false, type = "button", variant = "inline", customClass = "", icon = null, isLoading = false, appearance }) {
  const buttonFixedStyle = `font-medium px-3 py-2 rounded-lg transition-all border-4 whitespace-nowrap ${disabled ? "cursor-not-allowed opacity-50" : "cursor-pointer"}`;

  const variantStyles = {
    inline: {
      primary: `bg-primary text-textColor border-primary `,
      secondary: `bg-primary text-secondary border-tertiary ${!disabled && "hover:bg-tertiary"}`,
      success: `bg-transparent text-secondary border-tertiary ${!disabled && "hover:bg-primary hover:text-secondary"}`,
      error: `bg-errorPrimary text-primary border-errorSecondary ${!disabled && "hover:bg-errorSecondary hover:bg-transparent hover:text-errorPrimary"}`,
    },
    outline: {
      primary: `bg-transparent text-secondary border-secondary ${!disabled && "hover:text-primary hover:bg-secondary hover:border-tertiary"}`,
      success: `bg-transparent text-successPrimary border-successSecondary ${!disabled && "hover:text-secondary hover:bg-successPrimary"}`,
      error: `bg-transparent text-errorPrimary border-errorSecondary ${!disabled && "hover:text-secondary hover:bg-errorPrimary"}`,
    },
  };

  const variantType = variantStyles[variant] ? variant : "inline";
  const buttonStyle = variantStyles[variantType][appearance || "primary"];
  const borderColor = buttonStyle.split(" ").find((style) => style.startsWith("border-"));

  const loadingColors = {
    "border-primary": "border-t-primary",
    "border-secondary": "border-t-secondary",
    "border-errorPrimary": "border-t-errorPrimary",
    "border-successPrimary": "border-t-successPrimary",
    "border-errorSecondary": "border-t-errorSecondary",
    "border-successSecondary": "border-t-successSecondary",
  };

  const circularLoading = (borderColor) => {
    const newBorderColor = loadingColors[borderColor];
    return <div className={`border-tertiary h-7 w-7 md:h-10 md:w-10 animate-spin rounded-full border-8 ${newBorderColor}`} />;
  };

  return (
    <button className={`${buttonStyle} ${buttonFixedStyle} ${customClass}`} type={type} disabled={disabled || isLoading} onClick={onClick}>
      <div className="flex items-center justify-center text-md md:text-lg">
        {(isLoading || icon) && <span className={`${text ? "mr-2" : ""}`}>{isLoading ? circularLoading(borderColor) : icon}</span>} {text}
      </div>
    </button>
  );
}
