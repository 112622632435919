import { Toast } from "src/components";
import Client from "src/services/client";

export const updateOneAPICall = async (context, id, data) => {
  try {
    const response = await Client.put(`/${context}/${id}`, data);

    return response;
  } catch (error) {
    Toast(error.response.data.message || "Il y a une erreur sur le serveur, veuillez contacter le support", "error");
  }
};
