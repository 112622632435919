import { useState, useMemo, useEffect } from "react";

import socket from "src/socket";
import { onChange } from "src/utils";
import PaginatedTable from "./PaginatedTable";
import { CustomButton, CustomInput, LoadingSpinner, PageWrapper, Toast } from "src/components";
import { useCreateOneCall, useGetAllCall, useGetPaginatedCall, useUpdateOneCall } from "src/services";

const Calendar = () => {
  const { data, isLoading, isError, refetch: refetchCalendar } = useGetAllCall("events/get-admin-calendar");

  const events = useMemo(() => data?.data?.data, [data]);

  const initialValues = { name: "", description: "", capacity: 1 };

  const [values, setValues] = useState(initialValues);
  const [modalState, setModalState] = useState({ isModalOpen: false, selectedEvent: { _id: "", isEvent: false, date: "" } });

  const { data: fetchedReservationsData, isLoading: reservationsLoading, refetch: refetchReservations } = useGetPaginatedCall("reservations", 1, 10, "", modalState.selectedEvent._id, modalState.selectedEvent.isEvent);

  const reservationsData = fetchedReservationsData?.data?.data;

  useEffect(() => {
    const handleNewReservation = () => {
      refetchCalendar();
      if (modalState.selectedEvent.isEvent) refetchReservations();
    };

    const handleReservationCanceled = () => {
      refetchCalendar();
      if (modalState.selectedEvent.isEvent) refetchReservations();
    };

    socket.on("new_reservation", handleNewReservation);
    socket.on("reservation_canceled", handleReservationCanceled);

    return () => {
      socket.off("new_reservation", handleNewReservation);
      socket.off("reservation_canceled", handleReservationCanceled);
    };
  }, [refetchCalendar]);

  const handleEventClick = (item) => {
    if (modalState.selectedEvent._id !== item._id && item.isEvent) setModalState((prevState) => ({ ...prevState, selectedEvent: { name: item.name, _id: item._id, isEvent: true } }));
  };

  const handleIconClick = (item) => {
    setValues(item.isEvent ? { name: item.name, description: item.description || "" } : initialValues);
    setModalState({ ...modalState, isModalOpen: true, selectedEvent: { ...modalState.selectedEvent, isEvent: item.isEvent, date: item.date } });
  };

  const formInputs = [
    { id: 1, name: "name", type: "text", placeholder: "Nom" },
    { id: 2, name: "description", type: "text", placeholder: "Description" },
    { id: 3, name: "capacity", type: "number", min: 1, placeholder: "Capacité" },
  ];

  const createMutation = useCreateOneCall("events");
  const updateMutation = useUpdateOneCall("events", modalState.selectedEvent._id);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!values.name) return Toast("Le champ nom est obligatoire", "error");

    let mutation = modalState.selectedEvent.isEvent ? updateMutation : createMutation;

    let valuesToSend = { ...values, date: modalState.selectedEvent.date };

    mutation.mutate(valuesToSend, {
      onSuccess: (data) => {
        if (data?.data?.success) {
          Toast(data?.data?.message);
          refetchCalendar();
        }
        setModalState((prev) => ({ ...prev, isModalOpen: false }));
      },
      onError: (error) => Toast("Échec du traitement de la demande", "error"),
    });
  };

  const renderEventContent = (item) =>
    item.isEvent ? (
      <>
        <span className="text-lg" onClick={() => handleIconClick(item)}>
          📝
        </span>
        <span className="text-md text-red-400 truncate">{item.name}</span>
        <span className="text-sm text-gray-500 whitespace-nowrap">
          {item.acceptedReservationsCount} <span className="text-sm text-green-500 whitespace-nowrap"> / {item.reservationsCount} R. acceptées</span>
        </span>
        <p className="text-sm text-gray-500 whitespace-nowrap">
          {item.peopleComing} <span className="text-sm text-green-500 whitespace-nowrap"> / {item.capacity} 🙍‍♂️ venant</span>
        </p>
      </>
    ) : (
      <span className="text-lg" onClick={() => handleIconClick(item)}>
        ➕
      </span>
    );

  const renderCalendar = () => {
    return (
      <div className="md:max-h-full max-h-44 overflow-y-auto grid gap-2 mb-4 grid-cols-2 xl:grid-cols-7 bg-textColor">
        {events?.map((item, index) => (
          <div key={index} className={`py-2 text-center border-r border-b border-quaternary cursor-pointer ${modalState.selectedEvent._id === item._id ? "bg-green-200" : ""}`} onClick={() => handleEventClick(item)}>
            <div className="text-green-700 bg-quaternary p-2 rounded-xl font-bold text-xl select-none">{item.date}</div>
            <div className="event-content flex flex-col justify-center items-center overflow-hidden truncate">{renderEventContent(item)}</div>
          </div>
        ))}
      </div>
    );
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Failed to load events.</div>;

  return (
    <div className="w-full">
      <div className="flex justify-center mb-4">
        <div className="min-w-full">
          <div className="py-2 text-center text-2xl bg-secondary text-primary align-middle rounded-t-2xl">Calendrier</div>
          {renderCalendar()}
        </div>
      </div>

      {modalState.isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-secondary p-6 rounded shadow-lg w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">{modalState.selectedEvent.isEvent ? "Mettre à jour l'événement" : "Créer l'événement"}</h2>

            <form className="space-y-6" onSubmit={handleSubmit}>
              {formInputs.map((input) => (
                <CustomInput key={input.id} type={input.type} maxWidth="w-full" {...input} value={values[input.name]} onChange={onChange(setValues, values)} />
              ))}
              <div className="flex gap-4">
                <CustomButton type="submit" isLoading={isLoading} disabled={isLoading} text="Soumettre" customClass="w-full" />
                <CustomButton type="button" variant="inline" appearance="error" text="Fermer" onClick={() => setModalState({ ...modalState, isModalOpen: false })} customClass="w-full" />
              </div>
            </form>
          </div>
        </div>
      )}
      {modalState.selectedEvent.isEvent &&
        (reservationsLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <div className="flex justify-center">
              <h1 className="text-center text-2xl text-textColor bg-secondary p-2 rounded-md mb-2">
                "{reservationsData.name}" <span className="text-errorSecondary">le</span> {reservationsData.date.split("T")[0]}
                <span className="ml-4 text-green-400">
                  {reservationsData.totalAcceptedPeople} / {reservationsData.capacity}🙍‍♂️
                </span>
              </h1>
            </div>
            <PaginatedTable reservations={reservationsData?.reservations} refetchReservations={refetchReservations} />
          </>
        ))}
    </div>
  );
};

function App() {
  return (
    <PageWrapper title="Reservations">
      <Calendar />
    </PageWrapper>
  );
}

export default App;
