import { LoadingSpinner } from "src/components";

const NoData = ({ context }) => (
  <div className="flex flex-col items-center justify-center h-full">
    <h1 className="mt-[3%] text-2xl bg-secondary text-primary font-bold p-2 rounded-md whitespace-nowrap">😲 Aucun {context} trouvé 😲</h1>
  </div>
);

const Index = ({ isLoading = false, isError = false, context = "context", children, className = "" }) => {
  if (isLoading)
    return (
      <div className={`h-full flex items-center justify-center ${className}`}>
        <LoadingSpinner />
      </div>
    );

  if (isError) return <NoData context={context} />;

  return children;
};

export default Index;
