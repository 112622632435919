import { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import logoImage from "src/assets/triple-blanc.png";
import { useCreateOneCall, useGetAllCall } from "src/services";
import { CustomButton, Toast, ImageSlider, CustomInput, FetchStateHandler } from "src/components";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, { locale: "fr" });

const Calendar = ({ onDateClick, date }) => {
  const { data, isLoading, isFetching, isError } = useGetAllCall("events/get-public-calendar");
  const loading = isLoading || isFetching;

  const events = data?.data?.data;

  return (
    <div className="md:max-h-full max-h-44 overflow-y-auto grid gap-2 mb-4 grid-cols-2 sm:grid-cols-7">
      <FetchStateHandler isLoading={loading} isError={isError} context="Événement">
        {events?.map((event, index) => (
          <div key={index} className={`p-1 border-4 text-center rounded cursor-pointer ${event.isEvent ? "bg-green-200 hover:bg-green-300" : "bg-gray-200 hover:bg-gray-300"} ${date === event.date ? " border-primary" : ""}`} onClick={() => onDateClick(event)}>
            <p className="font-bold">{event.date}</p>
            {event.isEvent ? event.isComplete ? <p className="text-sm sm:text-md text-blue-600">complet</p> : <p className="text-sm sm:text-md text-green-600">{event.name}</p> : <p className="text-red-600">Pas de réserv.</p>}
          </div>
        ))}
      </FetchStateHandler>
    </div>
  );
};

const PaymentForm = ({ values, setValues, createReservationMutation, loading, initialValues }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    if (!values.name) return Toast("Veuillez renseigner votre nom :)");
    if (!values.date) return Toast("Veuillez renseigner une date :)");
    if (!values.nbOfPeople) return Toast("Veuillez renseigner le nombre de personnes :)");
    if (!values.phoneNumber) return Toast("Veuillez renseigner le numéro de téléphone :)");
    if (!values.email || !values.email.includes(".")) return Toast("Veuillez renseigner une adresse e-mail valide :)");

    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) return Toast(error.message);

    createReservationMutation.mutate(
      { token: token.id, ...values },
      {
        onSuccess: (data) => {
          if (data?.data?.success) {
            setValues(initialValues);
            Toast(data?.data?.message);
          }

          if (data?.data?.data?.requires_action && data?.data?.data?.next_action?.type === "redirect_to_url") {
            const { url } = data.data.data.next_action.redirect_to_url;
            window.location.href = url;
          }
        },
        onError: (error) => Toast("Une erreur s'est produite lors de la vérification du solde de la carte", "error"),
      }
    );
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4 sm:w-[80%] mx-auto">
      <CardElement options={{ hidePostalCode: true }} className="p-4 bg-white rounded-md flex-1 font-bold" aria-hidden="false" />
      <div className="flex flex-col md:flex-row gap-2 ">
        <CustomInput onChange={(e) => setValues((prev) => ({ ...prev, name: e.target.value }))} placeholder="Nom et Prénom*" maxWidth="w-full" />
        <CustomInput onChange={(e) => setValues((prev) => ({ ...prev, nbOfPeople: e.target.value }))} placeholder="Nb de pers*" type="number" min={1} maxWidth="w-full" />
        <CustomInput onChange={(e) => setValues((prev) => ({ ...prev, phoneNumber: e.target.value }))} placeholder="Numéro de télé*" type="phone" maxWidth="w-full" />
      </div>

      <CustomInput onChange={(e) => setValues((prev) => ({ ...prev, email: e.target.value }))} placeholder="Email*" type="email" maxWidth="w-full" />

      <CustomButton text={loading ? "Chargement..." : "Reserver"} type="submit" appearance="primary" disabled={loading} />
    </form>
  );
};

const WelcomePage = () => {
  const schedule = ["19:00H", "19:30H", "20:00H", "20:30H", "21:00H", "21:30H", "22:00H"];

  let initialValues = { name: "", email: "", nbOfPeople: 1, phoneNumber: "", date: null, timing: schedule[0] };

  const [values, setValues] = useState(initialValues);
  const [backgroundImage, setBackgroundImage] = useState("");

  useEffect(() => {
    const loadBackgroundImage = async () => {
      if (window.innerWidth < 640) {
        const { default: smallImage } = await import("src/assets/reservation-back-small.jpg");
        setBackgroundImage(smallImage);
      } else {
        const { default: largeImage } = await import("src/assets/reservation-back.jpg");
        setBackgroundImage(largeImage);
      }
    };

    loadBackgroundImage();
  }, []);

  const handleDateClick = (event) => {
    if (!event.isEvent) return Toast("Vous ne pouvez pas faire de réservation pour ce jour");
    if (event.isFullyBooked) return Toast("L'événement est complet");

    setValues((prev) => ({ ...prev, date: event.date, eventId: event._id }));
  };

  const scrollToBottomSection = () => window.scrollBy({ top: window.innerHeight, behavior: "smooth" });

  const createReservationMutation = useCreateOneCall("reservations");
  const loading = createReservationMutation.status === "pending";

  return (
    <div>
      <div className="relative overflow-hidden">
        <ImageSlider />
        <div className="absolute top-5 right-5 sm:top-5 sm:right-10 md:top-10 md:right-15 lg:top-10 lg:right-20 z-20 flex items-center gap-4">
          <a href="https://www.facebook.com/profile.php?id=61553866108762" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-4xl cursor-pointer text-secondary hover:text-primary" />
          </a>
          <a href="https://www.instagram.com/triple8mq" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-4xl cursor-pointer text-secondary hover:text-primary" />
          </a>
          <CustomButton text="Reserver" onClick={scrollToBottomSection} />
        </div>
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center pointer-events-none">
          <img src={logoImage} alt="Triple 8 Logo" className="w-full lg:w-1/2" />
        </div>
      </div>

      <div className="w-full  min-h-screen flex flex-col items-center justify-center pt-3" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
        <h1 className="text-xl md:text-4xl p-2 text-textColor bg-primary rounded-lg mx-2 text-center mb-4 md:w-7/8">Rejoignez-nous pour une aventure inoubliable au Triple 8</h1>
        <div className="bg-primary bg-opacity-70 p-4 rounded-md shadow-lg w-full max-w-4xl">
          <h1 className="text-lg mb-4 text-center p-2 rounded-lg bg-textColor text-primary font-bold">
            {values.date && values.timing ? `Vous planifiez une réservation pour ${values.date} à ${values.timing}` : "Sélectionnez la date et l'heure pour continuer"}
          </h1>

          <Calendar onDateClick={handleDateClick} date={values.date} />

          <div className="md:w-3/4 mx-auto flex flex-col gap-3 p-3 items-center border-y-4 border-textColor mb-4 rounded-xl">
            <div className="flex gap-4">
              {schedule.slice(0, 3).map((item, index) => (
                <h1
                  key={index}
                  className={`bg-primary flex items-center border-2 px-2 py-1 rounded-md cursor-pointer ${values.timing === item ? "text-primary bg-secondary" : "bg-primary text-textColor"}`}
                  onClick={() => setValues((prev) => ({ ...prev, timing: item }))}
                >
                  {item}
                </h1>
              ))}
            </div>

            <div className="flex gap-4">
              {schedule.slice(3, 7).map((item, index) => (
                <h1
                  key={index}
                  className={`bg-primary flex items-center border-2 px-2 py-1 rounded-md cursor-pointer ${values.timing === item ? "text-primary bg-secondary" : "bg-primary text-textColor"}`}
                  onClick={() => setValues((prev) => ({ ...prev, timing: item }))}
                >
                  {item}
                </h1>
              ))}
            </div>
          </div>

          <Elements stripe={stripePromise}>
            <h2 className="text-textColor text-center text-lg mb-1">Veuillez noter qu'en cas de non-présentation sans annulation préalable, un montant de 8€/pers sera débité de votre compte bancaire conformément à notre politique de réservation.</h2>
            <PaymentForm values={values} setValues={setValues} createReservationMutation={createReservationMutation} loading={loading} initialValues={initialValues} />
          </Elements>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
