import { useState } from "react";
import { Outlet } from "react-router-dom";

import { Sidebar } from "src/components";
import { Header } from "src/components";

export default function Layout({ tabs }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className="flex flex-row bg-secondary w-full min-h-screen ">
      <Sidebar tabs={tabs} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      <div className={`flex-1 overflow-y-auto duration-500 ${isSidebarOpen ? "ml-[13rem]" : "ml-[3rem]"}`}>
        <Header />
        <Outlet />
      </div>
    </div>
  );
}
