import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { useGetAllCall } from "src/services";
import { LoadingSpinner, Toast } from "src/components";

const PaymentResult = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");

  const query = new URLSearchParams(location.search);

  const sourceType = query.get("source_type");
  const paymentIntent = query.get("payment_intent");
  const paymentIntentClientSecret = query.get("payment_intent_client_secret");

  useEffect(() => {
    if (paymentIntent && paymentIntentClientSecret && sourceType) {
      setMessage("Votre paiement a été traité avec succès !");
      Toast("Votre paiement a été traité avec succès !");
    } else {
      setMessage("Une erreur s'est produite lors du traitement de votre paiement.");
      Toast("Une erreur s'est produite lors du traitement de votre paiement.", "error");
    }
  }, [location.search]);

  const { data, isLoading, isFetching, ...rest } = useGetAllCall(`reservations/update-status?paymentIntentId=${paymentIntent}`);

  const loading = isLoading || isFetching;
  // console.log("data", data);
  // console.log("rest", rest);

  return <div className="w-full min-h-screen flex flex-col items-center justify-center">{loading ? <LoadingSpinner /> : <h1 className="text-2xl text-primary text-center mb-4">{message}</h1>}</div>;
};

export default PaymentResult;
