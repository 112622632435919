import { useState, useEffect } from "react";

import { useCreateOneCall } from "src/services";
import { CustomButton, Toast } from "src/components";

const columnsLarge = [
  { key: "name", label: "Nom" },
  { key: "email", label: "Email" },
  { key: "phoneNumber", label: "Numéro de téléphone" },
  { key: "nbOfPeople", label: "Nombre de pers." },
  { key: "timing", label: "Timing" },
  { key: "status", label: "Montant" },
];

const columnsSmall = [
  { key: "name", label: "Nom" },
  { key: "nbOfPeople", label: "🙍‍♂️" },
  { key: "status", label: "Montant" },
];

const PaginatedTable = ({ reservations, refetchReservations }) => {
  const rowsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const withdrawalMutation = useCreateOneCall("reservations/withdraw");
  const withdrawalLoading = withdrawalMutation.status === "pending";

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleButtonClick = (reservation) => {
    withdrawalMutation.mutate(
      { reservationId: reservation._id },
      {
        onSuccess: (data) => {
          if (data?.data?.success) {
            Toast(data?.data?.message);
            refetchReservations();
          }
        },
        onError: (error) => Toast("Une erreur s'est produite lors de la vérification du solde de la carte", "error"),
      }
    );
  };

  const renderTable = () => {
    const start = (currentPage - 1) * rowsPerPage;
    const end = start + rowsPerPage;
    const paginatedReservations = reservations.slice(start, end);

    if (paginatedReservations.length === 0) {
      return <p className="text-center py-4">Aucune réservation trouvée.</p>;
    }

    const columns = windowWidth >= 768 ? columnsLarge : columnsSmall;

    return (
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            {columns.map((column) => (
              <th key={column.key} className="px-4 py-2 border-b text-center align-middle">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {paginatedReservations.map((reservation, index) => (
            <tr key={index} className="border-b">
              {columns.map((column) => (
                <td key={column.key} className="px-4 py-2 align-middle text-center">
                  {column.key === "status" ? (
                    reservation.status === "requires_capture" ? (
                      <CustomButton text={`${reservation.amount / 100}💶`} onClick={() => handleButtonClick(reservation)} appearance="primary" />
                    ) : reservation.status === "succeeded" ? (
                      <span>payé {reservation.amount / 100}💶</span>
                    ) : (
                      reservation.status
                    )
                  ) : (
                    reservation[column.key]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNext = () => {
    const totalPages = Math.ceil(reservations.length / rowsPerPage);
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const start = (currentPage - 1) * rowsPerPage;
  const end = start + rowsPerPage;
  const paginatedReservations = reservations.slice(start, end);

  return (
    <div className="container mx-auto">
      {paginatedReservations.length === 0 ? (
        <p className="text-center py-4 text-secondary">Aucune réservation trouvée.</p>
      ) : (
        <>
          {renderTable()}
          <div className="flex justify-between mt-4">
            <CustomButton text="Précédent" onClick={handlePrevious} customClass="bg-gray-300 text-gray-700 hover:bg-gray-400" disabled={currentPage === 1} />
            <CustomButton text="Suivant" onClick={handleNext} customClass="bg-gray-300 text-gray-700 hover:bg-gray-400" disabled={currentPage === Math.ceil(reservations.length / rowsPerPage)} />
          </div>
        </>
      )}
    </div>
  );
};

export default PaginatedTable;
