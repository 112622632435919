import { useQuery } from "@tanstack/react-query";

import { getAllAPICall } from "../apiCalls";

export function useGetAllCall(context) {
  return useQuery({
    queryKey: [context],
    queryFn: () => getAllAPICall(context),
    keepPreviousData: false,
    cacheTime: 0,
    staleTime: 0,
    refetchInterval: 0,
  });
}
