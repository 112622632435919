import { Toast } from "src/components";
import Client from "src/services/client";

export const getAllAPICall = async (context) => {
  try {
    const response = await Client(`/${context}`);

    return response;
  } catch (error) {
    Toast(error.response.data.message || "Il y a une erreur sur le serveur, veuillez contacter le support", "error");
  }
};
