import Cookies from "js-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, createContext, useEffect } from "react";

import { Toast } from "src/components";
import { useCreateOneCall } from "src/services";

export const userContext = createContext();

const UserContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState(null);

  const verifyToken = useCreateOneCall("auth/verify-login");

  const isLoading = verifyToken.status === "pending";

  useEffect(() => {
    const token = Cookies.get("x-triple-8");
    if (location.pathname === "/") return;
    if (location.pathname === "/cancel-reservation") return;

    if (!token) return navigate("/auth/login");

    if (token && !user) {
      verifyToken.mutate(token, {
        onSuccess: (data) => {
          if (data?.data?.success) {
            setUser(data.data.data.user);
            navigate("/dashboard/reservations");
            Toast(data.data.message);
          } else navigate("/auth/login");
        },
        onError: (error) => {
          Toast("Votre session est terminée veuillez vous reconnecter", "error");
          navigate("/auth/login");
        },
      });
    }
  }, []);

  return <userContext.Provider value={{ user, setUser, isLoading }}>{children}</userContext.Provider>;
};

export default UserContextProvider;
