import { getInputStyles, inputStyle } from "src/components/Forms/InputStyles";

const CustomInput = ({ onChange, label, required, pattern, errorMessage, maxWidth = "max-w-[350px]", ...inputProps }) => {
  const { type, options, ...otherInputProps } = inputProps;
  const hasLabel = !!label;

  const styles = getInputStyles(type === "checkbox", hasLabel);

  if (type === "select") {
    return (
      <div className={styles.inputOuterDivStyle}>
        {hasLabel && <label className={styles.inputLabelStyle}>{label}</label>}
        <div className={styles.inputInnerDivStyle}>
          <select onChange={onChange} className={`${inputStyle} ${maxWidth}`} {...otherInputProps}>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.inputOuterDivStyle}>
      {hasLabel && <label className={styles.inputLabelStyle}>{label}</label>}
      <div className={styles.inputInnerDivStyle}>
        <input type={type} onChange={onChange} className={`${inputStyle} ${maxWidth}`} autoComplete="off" {...otherInputProps} />
      </div>
    </div>
  );
};

export default CustomInput;
