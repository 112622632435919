import { Toast } from "src/components";
import Client from "src/services/client";

//THE FUNCTION BELOW REMOVES A PARAMETER IF IT HAS NO VALUE
const appendParam = (params, key, value) => (value !== undefined && value !== null ? params.append(key, value) : null);

export const getPaginatedAPICall = async (context, pageNo, limit, queryText, specialQuery) => {
  try {
    const params = new URLSearchParams();
    appendParam(params, "page", pageNo);
    appendParam(params, "limit", limit);
    appendParam(params, "queryText", queryText);
    appendParam(params, "specialQuery", specialQuery);

    const response = await Client(`/${context}/all?${params.toString()}`);

    return response;
  } catch (error) {
    Toast(error.response.data.message || "Il y a une erreur sur le serveur, veuillez contacter le support", "error");
  }
};
