import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Notify(message = "this is my message", type) {
  // type can be "success", "error", "info", "warning" or "default"

  toast(message, {
    type,
    autoClose: 8000,
    draggable: false,
    closeOnClick: true,
    pauseOnHover: true,
    position: "top-right",
    pauseOnFocusLoss: false,
    className: "text-lg font-bold",
  });
}

export const Toast = () => <ToastContainer />;
