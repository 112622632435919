export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const onChange = (setValues, values) => (e) => {
  const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
  setValues({ ...values, [e.target.name]: value });
};

export function toSingular(word) {
  //Exceptions
  if (word === "employees") return "employee";
  //Exceptions

  if (word.endsWith("ies")) return word.slice(0, -3) + "y";
  else if (word.endsWith("cles")) return word.slice(0, -1);
  else if (word.endsWith("es")) return word.slice(0, -2);
  else if (word.endsWith("s")) return word.slice(0, -1);
  else return word;
}
