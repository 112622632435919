import { useMutation } from "@tanstack/react-query";

import { updateOneAPICall } from "../apiCalls";

export function useUpdateOneCall(context, id) {
  const mutation = useMutation({
    mutationFn: async (values) => await updateOneAPICall(context, id, values),
    enabled: id !== "",
  });

  return mutation;
}
