import { Route, Routes } from "react-router-dom";
import { useContext, Suspense, lazy } from "react";

import Icons from "./Sidebaricons";
import { userContext } from "./context";
import { Toast } from "./components/shared/Toast";
import { Layout, LoadingSpinner } from "src/components";
import { AuthRouting, WelcomePage, PaymentResult, CancelReservation } from "./Pages";

const DashboardRouting = lazy(() => import("./Pages").then((module) => ({ default: module.DashboardRouting })));

const App = () => {
  const routes = [{ id: 1, path: "reservations", element: <DashboardRouting />, title: "reservations", icon: <Icons.GiNotebook size={25} /> }];

  const nonSidebarRoutes = [
    { id: 1, path: "/", element: <WelcomePage /> },
    { id: 2, path: "/auth/*", element: <AuthRouting /> },
    { id: 3, path: "/return", element: <PaymentResult /> },
    { id: 3, path: "/cancel-reservation", element: <CancelReservation /> },
  ];

  const { user, isLoading } = useContext(userContext);

  const sidebarRoutes = routes.map(({ element, id, ...rest }) => ({ ...rest, key: id }));
  const nonSidebarRoute = nonSidebarRoutes.map(({ path, element }, index) => <Route key={index} path={path} element={element} />);
  const routeComponents = routes.map(({ path, element }, index) => <Route key={index} path={path} element={element} />);

  if (isLoading)
    return (
      <div className="flex h-full justify-center items-center">
        <LoadingSpinner />
      </div>
    );

  return (
    <>
      <Toast />
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {nonSidebarRoute}

          {user && (
            <Route path="/dashboard/*" element={<Layout tabs={sidebarRoutes} />}>
              {routeComponents}
            </Route>
          )}
        </Routes>
      </Suspense>
    </>
  );
};

export default App;
