import axios from "axios";
import Cookies from "js-cookie";

const baseUrl = process.env.REACT_APP_API_URL;

const client = axios.create({ baseURL: baseUrl + "/api" });

client.interceptors.request.use((config) => {
  const token = Cookies.get("x-triple-8");
  if (token) config.headers.Authorization = `Bearer ${token}`;

  return config;
}, Promise.reject);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Cookies.remove("x-triple-8");
      window.location = "/auth/login";
    }
    return Promise.reject(error);
  }
);

export default client;
